module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pilot-public","short_name":"pilot","start_url":"/","background_color":"#1640F8","theme_color":"#1640F8","display":"minimal-ui","icon":"src/images/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dfd59a187bc9f3a64c1bf9379b2356bb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-129207802-1","head":true,"optimizeId":"GTM-P2ZV7XQ"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
